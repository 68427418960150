import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Planes from "../components/planes"
import BloqueContacto from "../components/bloque-contacto"
import imgAsk from '../images/dibus/ask.svg'

const PlanesPage = () => (
  <Layout>
    <SEO title="Planes" />
    <section className="bloque bloque-main bloque-planes-main">
		<div className="container">
		 	<h1 className="main-title">Planes</h1>
		 	<p>
		 		No hay proyecto pequeño porque todos los hacemos con mucho cariño, pero según las necesidades algunos requieren más horas de amor que otros, así que para que tengas una orientación hemos creado 4 planes. 
		 		Tenemos el plan perfecto para tí: desde una web de presencia corporativa hasta una completa aplicación web que te ayude a gestionar tu empresa. 
		 	</p>
			
		</div>
    </section>

    <section className="bloque bloque-planes">
	    <div className="container">
	    	<Planes />

	    	<p className="small-text">* Todos los precios son orientativos ya que cada proyecto tiene unas necesidades específicas y necesita de un estudio personalizado para cada caso.</p>
			<p className="small-text">** Todos los precios son sin IVA.</p>
		</div>
    </section>

    <section className="bloque bloque-call-to-action bg-dark-blue">
	    <div className="container">
	    	<h4>¿Te interesa alguno de nuestros planes? ¿Quieres que te hagamos un presupuesto personalizado?</h4>
	    	<p>También tenemos super planes de mantenimiento y super packs de horas para que tu web esté siempre al día. Pídenos información, seguro que te acabas quedando con un super plan. </p>

			<Link to="/contacto" className="btn btn-accent">Ir a contacto</Link>
		</div>
    </section>    

    <section className="bloque bloque-faq">
    	<div className="container">
	    	<h2 className="title">FAQ - Preguntas Frecuentes</h2>

    		<div className="row">
				<div className="col-md-6">
					<p className="faq-question">¿Qué necesito para empezar con el diseño de mi web?</p>
					<p className="faq-answer">Antes de nada debemos saber de qué trata tu negocio, qué ofreces y quiénes son tus clientes. A partir de ahí empezaremos a definir exactamente qué es lo que necesitas.

					Para poder llevar la web a cabo necesitaremos que nos proporciones todo lo necesario para poder trabajar. ¿Qué es lo necesario? Bueno, eso dependerá del tipo de web, pero principalmente necesitaremos el logotipo en la mejor calidad posible (preferiblemente vectorizado) y luego todo lo que nos puedas dar: catálogos, textos, fotografías, etc.
					</p>
				</div>
				<div className="col-md-6">
					<p className="faq-question">¿Qué ocurre si el plazo de entrega se retrasa?</p>
					<p className="faq-answer">No aceptamos proyectos si sabemos que no podemos cumplir con los plazos, así que te aseguramos que siempre se entregará a tiempo. Eso sí, para poder cumplir con los plazos es imprescindible que se haya realizado la aceptación del proyecto (es decir €€€) y entregado todo el material necesario para poder trabajar. Si no tenemos el material a tiempo no podemos hacer magia, así que en ese caso el retraso no sería nuestra responsabilidad.</p>
				</div>		
				<div className="col-md-6">
					<p className="faq-question">¿El precio incluye el dominio y hosting?</p>
					<p className="faq-answer">No, pero podemos ayudarte en el proceso de registro de dominio y de compra de alojamiento si lo necesitas.</p>
				</div>
				<div className="col-md-6">
					<p className="faq-question">¿Cuánto cuesta una web? </p>
					<p className="faq-answer">Hemos escuchado esta pregunta tantas veces… y la respuesta es “depende”. ¿Cuanto vale un coche? Puedes tener uno de segunda mano por 1000€ o puedes tener un coche de lujo más caro que un piso en Barcelona. Con una web pasa lo mismo y el precio depende de cómo deba ser. Para tener una orientación de nuestra horquilla de precios, échale un vistazo a nuestros 4 super planes.</p>
				</div>		

				<div className="col-md-6">
					<p className="faq-question">¿Tengo que pagar por adelantado?</p>
					<p className="faq-answer">En general se realizará un primer pago del 50% como aceptación del proyecto y el otro 50% se abonará a la finalización del mismo. En algunos planes las condiciones de pago varían ofreciendo facilidad de pago en tres plazos.
					</p>
				</div>
				<div className="col-md-6">
					<p className="faq-question">¿Vuestras webs son plantillas de Wordpress?</p>
					<p className="faq-answer">Solo trabajamos con Wordpress si es un requerimiento explícito del cliente que nos indica que quiere que trabajemos con esta herramienta. Wordpress tiene muchas cosas buenas, pero a nosotros nos gustan las webs bien hechas, las webs rápidas, eficientes,... </p>
				</div>		
				<div className="col-md-6">
					<p className="faq-question">Es que yo sólo necesito unos cambios en mi web...</p>
					<p className="faq-answer">No es necesario contratar un plan completo, todo es hablarlo. Contáctanos indicando qué necesitas y veremos cómo podemos ayudarte.</p>
				</div>
				<div className="col-md-6">
					<p className="faq-question">No tengo logotipo… ¿me lo podéis hacer?</p>
					<p className="faq-answer">El diseño de logotipos no está contemplado en los planes, pero en nuestro equipo tenemos diseñadores gráficos que pueden crear un logotipo para tí sin problema.</p>
				</div>									
			</div>	

			<img src={imgAsk} className="img-ask" alt="" title="FAQ" />
    	</div>
    </section>

    <BloqueContacto />

  </Layout>
)

export default PlanesPage